<template>
  <div>
    <siteHeader />
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Seat Management</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col text-left">
                  <base-button
                    type="primary"
                    size="sm"
                    @click="
                      $router.push('/seatManagement/create_seatmanagement')
                    "
                    >Create</base-button
                  >
                </div>
                <div class="col" style="padding-left: 500px">
                  <div class="search-wrapper panel-heading col-sm-12">
                    <input
                      class="form-control"
                      type="text"
                      v-model="searchQuery"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>

              <br />
              <div class="row1">
                <div class="table-responsive">
                  <base-table
                    class="table align-items-center table-flush"
                    :class="type === 'dark' ? 'table-dark' : ''"
                    :thead-classes="
                      type === 'dark' ? 'thead-dark' : 'thead-light'
                    "
                    tbody-classes="list"
                    :data="filteredResources"
                  >
                    <template v-slot:columns>
                      <th>Name</th>
                      <th>Membership</th>
                      <th>Members Group</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Address</th>
                      <th>Active</th>
                      <th>Action</th>
                    </template>

                    <template v-slot:default="row">
                      <td class="budget">
                        {{ row.item.name }}
                      </td>
                      <td class="budget">
                        {{ row.item.membership }}
                      </td>
                      <td class="budget">
                        {{ row.item.membergroup }}
                      </td>
                      <td class="budget">
                        {{ row.item.phone }}
                      </td>

                      <td class="budget">
                        {{ row.item.email }}
                      </td>

                      <td class="budget">
                        {{ row.item.address }}
                      </td>
                      <td class="budget">
                        {{ row.item.active }}
                      </td>
                      <td>
                        <i class="fas fa-trash-alt"></i>
                        &nbsp;
                        <i class="fas fa-edit"></i>
                        &nbsp;
                        <i class="far fa-eye"></i>
                      </td>
                    </template>
                  </base-table>
                </div>

                <div
                  class="card-footer d-flex justify-content-end"
                  :class="type === 'dark' ? 'bg-transparent' : ''"
                >
                  <!-- <base-pagination total="30"></base-pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "projects-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      searchQuery: "",
      tableData: [
        {
          name: "abc",
          membership: "membership1",
          membergroup: "xyz",
          phone: "11223344556",
          email: "xyz@gmail.com",
          address: "abc",
          active: "Active",
        },
      ],
    };
  },
  computed: {
    filteredResources() {
      if (this.searchQuery) {
        return this.tableData.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.membership.toLowerCase().includes(v));
        });
      } else {
        return this.tableData;
      }
    },
  },
};
</script>
<style></style>
